import axios from 'axios';
import { BASE_URL} from './CommonExport';
const fetchData = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/authenticate`,
        {}, // Request body (empty in this case)
        {
          withCredentials: true, // Ensure cookies are included in the request
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      console.log('response',response)
      if(response?.data?.statusCode == 10000){
        return true;
      }
      else{
      return false

      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
      window.alert('User is not authenticated');
      window.location = '/login';
    }
  };

  export default fetchData;