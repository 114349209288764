import React, { useState, useEffect } from 'react';
import Component from '../../components/Component'
import { BASE_URL, handleDynamicRequest } from '../../components/CommonExport';
import { TextField, Grid, Autocomplete, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Matches() {
    const [matchData, setMatchData] = useState([]);
    const [searchText, setSearchText] = React.useState('');
    const [filteredData, setFilteredData] = React.useState(matchData);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/tracking/fetchTrackingMatches`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
                    },
                });
                const data = await response.json();
                setMatchData(data.data.users);
            } catch (error) {
                console.error('Error fetching matches for dropdown:', error);
            }
        };
        fetchData();
    }, []);


    const [dateFromFilter, setDateFromFilter] = useState('');
    const [dateToFilter, setDateToFilter] = useState('');
    const [matchFilter, setMatchFilter] = useState(null);

    const filteredMatchPosts = matchData?.filter(post => {
        // Filter by date range
        if (dateFromFilter && new Date(post.date) < new Date(dateFromFilter)) {
            return false;
        }
        if (dateToFilter && new Date(post.date) > new Date(dateToFilter)) {
            return false;
        }
        // Filter by match ID
        if (matchFilter && matchFilter.id !== 'all' && post.matchId !== matchFilter.id) {
            return false;
        }
        return true;
    });

    useEffect(() => {
        const requestBody = {};
        if (dateFromFilter) {
            requestBody.startDate = dateFromFilter;
        }
        if (dateToFilter) {
            requestBody.endDate = dateToFilter;
        }

        if (matchFilter && matchFilter.id !== 'all') {
            requestBody.match_id = matchFilter.match_id;
        }

        fetch(`${BASE_URL}/tracking/fetchTrackingMatches`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'

            },
            body: JSON.stringify(requestBody)
        })
            .then(response => response.json())
            .then(data => {
                setMatchData(data.data.users);
                setFilteredData(data.data.users);
            })
            .catch(error => console.error('Error fetching table data:', error));
    }, [dateFromFilter, dateToFilter, matchFilter]);

    const columns = [
        { field: 'title', headerName: 'Match', flex: 1 },
        { field: 'total_feed_visits', headerName: 'Views', flex: 1 },
        { field: 'feeds_count', headerName: 'Posts', flex: 1 },
        { field: 'engagement', headerName: 'Engagement', flex: 1 },
        { field: 'rating_count', headerName: 'Number of Ratings', flex: 1 },
        { field: 'avg_rating', headerName: 'Average Rating', flex: 1 },
    ];

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);
        const filteredRows = matchData.filter((row) => {
            return Object.values(row).some((field) =>
                String(field).toLowerCase().includes(value)
            );
        });
        setFilteredData(filteredRows);
    };

    console.log(dateFromFilter)

    return (


        <Component>
            <div class="page-body">
                <div class="container-fluid">
                    <div class="page-header">
                        <div class="row">
                              <div className="col-sm-12">
                                <div className="d-flex align-items-center ">
                                    <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/content-dashboard">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <h3 style={{ marginLeft: '20px' }}>Matches Table</h3>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="row">

                        {/* <div className="col-12 col-sm-6 col-md-4">
                            <input
                                className="form-control"
                                type="date"
                                value={dateFromFilter}
                                onChange={(e) => setDateFromFilter(e.target.value)}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4">
                            <input
                                className="form-control"
                                type="date"
                                value={dateToFilter}
                                onChange={(e) => setDateToFilter(e.target.value)}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-4">
                            <select
                                className="form-select"
                                value={matchFilter?.match_id}
                                onChange={(e) => setMatchFilter({ match_id: e.target.value })}
                            >
                                <option value="all">All</option>
                                {matchData?.map((match) => (
                                    <option key={match.match_id} value={match.match_id}>
                                        {match.title}
                                    </option>
                                ))}
                            </select>
                        </div> */}
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                label="From Date"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                value={dateFromFilter}
                                                onChange={(e) => setDateFromFilter(e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                label="To Date"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                value={dateToFilter}
                                                onChange={(e) => setDateToFilter(e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Autocomplete
                                                id="match-filter"
                                                options={[{ id: 'all', name: 'All' }, ...matchData]}
                                                getOptionLabel={(option) => option.title}
                                                value={matchFilter}
                                                onChange={(event, newValue) => {
                                                    setMatchFilter(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Select Match" variant="outlined" fullWidth />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Search"
                                                variant="outlined"
                                                value={searchText}
                                                onChange={handleSearch}
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* <div class="table-responsive">
                                        <div className="table-responsive">
                                            <table className="display" id="basic-2">
                                                <thead>
                                                    <tr>
                                                        <th>Match</th>
                                                        <th>Views</th>
                                                        <th>Posts</th>
                                                        <th>Engagement</th>
                                                        <th>Number of Ratings</th>
                                                        <th>Average Rating</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {matchData.map((post) => (
                                                        <tr key={post.match_id}>
                                                            <td>{post.title}</td>
                                                            <td>{post.feed_views}</td>
                                                            <td>{post.feeds_count}</td>
                                                            <td>{post.engagement}</td>
                                                            <td>{post.rating_count}</td>
                                                            <td>{post.avg_rating}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div> */}

                                    <DataGrid
                                        rows={filteredData}
                                        columns={columns}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                        getRowId={(row) => row.match_id}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </Component>

    )
}

export default Matches
