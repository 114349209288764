import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { publicRoutes, authProtectedRoutes } from "./Routes";
import { useSelector } from 'react-redux';
import fetchData from "./components/authenticate";
import './index.css';

// Separate component to handle routes and conditional fetchData call
function RoutesHandler() {
  const location = useLocation(); // Correctly using useLocation inside Router context
  const sessionToken = sessionStorage.getItem('sessionToken');
  const userId = useSelector((state) => state.roleReducer.userId);
  const userPermissions = useSelector((state) => state.roleReducer.permissions);
  const superAdmin = useSelector((state) => state.roleReducer.isSuperAdmin);

  console.log(superAdmin, userPermissions)

  // Conditionally call fetchData only if the current route is not "/login"
  if (location.pathname !== '/login') {
    const response = fetchData();
    if(response){
      console.log('User is authenticated')
    }
    else{
    window.alert('User is not authenticated');
    window.location = '/login';
    }

  }

  return (
    <Routes>
      { publicRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={<route.component />}
          />
        ))
      }

      {authProtectedRoutes.map((route, idx) => (
        <Route
          key={idx}
          path={route.path}
          element={
            (superAdmin || (userId && userPermissions.includes(route.id.toString())) ) ? <route.component /> : <Navigate to="/access-denied" />
          }
        />
      ))}
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <RoutesHandler /> {/* Use RoutesHandler inside Router context */}
    </Router>
  );
}

export default App;
