import React, { useState, useEffect } from 'react';
import Component from '../../components/Component'
import { BASE_URL, handleDynamicRequest } from '../../components/CommonExport';
import { TextField, Grid, Autocomplete, IconButton, Button, Card, Typography, Switch } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { setUserId, setUserRole, setUserName, setJwtToken, setU_photo } from '../../Store/Role/action';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Highlights from '../Highlights/Highlights';
import Polls from '../Polls/Polls';
import Posts from '../Posts/Posts';
import Sponsors from '../Sponsors/Sponsors';
import Predictions from '../predictions/predictions';
import axios from 'axios';

function MatchDetails() {

    const matches = useSelector((state) => state.matchReducer.matches);
    const [matchStatus, setMatchStatus] = useState('');
    const [matchFeeds, setMatchFeeds] = useState('');
    const [matchRating, setMatchRating] = useState('');
    const [forceActiveState, setForceActiveState] = useState(false);
    const [forceActiveLoading, setForceActiveLoading] = useState(false);

    useEffect(() => {

        fetch(`${BASE_URL}/matches/active/${matches.match_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'

            },

        })
            .then(response => response.json())
            .then(data => {
                setMatchStatus(data.data[0].isactive);
                setMatchFeeds(data.data[0].feeds_post);
                setMatchRating(data.data[0].rate_players);
            })
            .catch(error => console.error('Error fetching table data:', error));
    }, []);

    useEffect(() => {
        const fetchForceActiveStatus = async () => {
        try{
            const response = await axios.get(`${BASE_URL}/admin/force-active-status/${matches.match_id}`);
            const status = response.data.data[0].forced_active
            setForceActiveState(status)
    }catch(err){
        console.log(err)
        window.alert('Error')
    }
}
fetchForceActiveStatus()
    } , []);



    const changeTrending = () => {
        fetch(`${BASE_URL}/matches/active-change/${matches.match_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'

            },

        })
            .then(response => response.json())
            .then(data => {
                setMatchStatus(data.data[0].isactive)
            })
            .catch(error => console.error('Error fetching table data:', error));
    }

    const changeFeeds = () => {
        fetch(`${BASE_URL}/matches/feeds-change/${matches.match_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
            },

        })
            .then(response => response.json())
            .then(data => {
                setMatchFeeds(data.data[0].feeds_post);
            })
            .catch(error => console.error('Error fetching table data:', error));
    }

    const changeRating = () => {
        fetch(`${BASE_URL}/matches/rating-change/${matches.match_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'

            },

        })
            .then(response => response.json())
            .then(data => {
                setMatchRating(data.data[0].rate_players);
            })
            .catch(error => console.error('Error fetching table data:', error));
    }

    const forceActive = () => {
        const changeForceActive = async () => {
        try {
            setForceActiveLoading(true)
            const response = await axios.put(`${BASE_URL}/admin/force-active/${matches.match_id}`, { enable: forceActiveState }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setForceActiveLoading(false)
        } catch (err) {
            console.log(err)
        }
    }
    changeForceActive()
    }
   


    return (


        <Component>
            <div class="page-body">
                <div class="container-fluid">
                    <div class="page-header">
                        <div class="row">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center ">
                                    <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/match-panel">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <h3 style={{ marginLeft: '20px' }}>Match Details</h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="row">


                        <div class="col-sm-12">
                            <div class="card">

                                <Accordion style={{ marginBottom: 0 }} defaultExpanded>
                                    <AccordionSummary

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h5 style={{ margin: 0, marginRight: '10px' }}>{matches.competition__title}</h5>
                                            <h7 style={{ margin: 0 }}>{matches.title}</h7>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Controls</h6>
                                        </div>
                                    </AccordionSummary>
                                    {/* 
                                    <AccordionDetails>
                                        {['Trending Players', 'Create Post', 'Rate Players'].map((title) => (
                                            <Card style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', marginBottom: '10px', marginLeft: '100px', marginRight: '100px' }} key={title}>
                                                <Typography variant="h7">{title}</Typography>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography variant="body1" style={{ marginRight: '10px' }}>Active</Typography>
                                                    <Switch />
                                                </div>
                                            </Card>
                                        ))}
                                    </AccordionDetails> */}
                                    <AccordionDetails>
                                        {/* {['Trending Players', 'Create Post', 'Rate Players'].map((title) => (
                                            <Card
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    padding: '10px',
                                                    marginBottom: '10px',
                                                    marginLeft: '100px',
                                                    marginRight: '100px'
                                                }}
                                                key={title}
                                            >
                                                <Typography variant="h7">{title}</Typography>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography variant="body1" style={{ marginRight: '10px' }}>Active</Typography>
                                                    {title === 'Trending Players' ? (
                                                        <Switch checked={matchStatus} onChange={changeTrending} />
                                                    ) : title === 'Create Post' ? (
                                                        <Switch checked={matchFeeds} onChange={changeFeeds} />
                                                    ) : (
                                                        <Switch checked={matchRating} onChange={changeRating} />
                                                    )}
                                                </div>
                                            </Card>
                                        ))} */}
                                        <Card
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: '10px',
                                                marginBottom: '10px',
                                                marginLeft: '100px',
                                                marginRight: '100px'
                                            }}
                                        >
                                            <Typography variant="h7">Force Active</Typography>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="body1" style={{ marginRight: '10px' }}>Active</Typography>
                                                <Switch checked={forceActiveState} onChange={() => setForceActiveState(!forceActiveState)} />
                                                <Button onClick={forceActive}>{forceActiveLoading?'saving...':'Save'}</Button>
                                            </div>
                                        </Card>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Highlights</h6>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Highlights />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Polls</h6>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Polls />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Prediction Polls</h6>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Predictions />
                                    </AccordionDetails>
                                </Accordion>


                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Create Post</h6>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Posts />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion style={{ marginBottom: 0, marginTop: 1 }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <h6 style={{ margin: 0, marginRight: '10px' }}>Sponsored Posts</h6>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Sponsors />
                                    </AccordionDetails>
                                </Accordion>


                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </Component>

    )
}

export default MatchDetails
