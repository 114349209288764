import React, { useState, useEffect, useRef } from 'react';
import Component from '../../components/Component'
import { BASE_URL, handleDynamicRequest } from '../../components/CommonExport';
import 'datatables.net-dt/css/dataTables.dataTables.min.css';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery';
import 'datatables.net';
import { TextField, Grid, Autocomplete, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';

function Players() {
    const [playerData, setPlayerData] = useState([]);
    const [matchData, setMatchData] = useState([]);
    const [searchText, setSearchText] = React.useState('');
    const [filteredData, setFilteredData] = React.useState(playerData);


    // useEffect(() => {
    //     fetch(`${BASE_URL}/tracking/fetchTrackingPlayers`, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
    //         },
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             setPlayerData(data.data.users);
    //             setFilteredData(data.data.users);
    //         })
    //         .catch(error => console.error('Error fetching matches for dropdown:', error));
    // }, []);

    const playerNames = playerData?.map(player => player.title);
    const [playerNameFilter, setPlayerNameFilter] = useState('');
    const [dateFromFilter, setDateFromFilter] = useState('');
    const [dateToFilter, setDateToFilter] = useState('');
    const [matchFilter, setMatchFilter] = useState(null);

    // Filter player data based on filter values
    const filteredPlayerData = playerData?.filter(player => {
        // Filter by player name
        if (playerNameFilter && !player?.title.toLowerCase().includes(playerNameFilter.toLowerCase())) {
            return false;
        }
        // Find the corresponding match data
        const match = matchData.find(match => match.id === player.matchId);
        if (!match) {
            return false; // Return false if no match is found for the player
        }
        // Filter by match date range
        if (dateFromFilter && new Date(match.date) < new Date(dateFromFilter)) {
            return false;
        }
        if (dateToFilter && new Date(match.date) > new Date(dateToFilter)) {
            return false;
        }
        // Filter by match name
        if (matchFilter && matchFilter.id !== 'all' && player.matchId !== parseInt(matchFilter.id)) {
            return false;
        }
        return true;
    });


    useEffect(() => {
        // Prepare request body
        const requestBody = {};

        // Add start and end dates to request body if selected
        if (dateFromFilter) {
            requestBody.startDate = dateFromFilter;
        }
        if (dateToFilter) {
            requestBody.endDate = dateToFilter;
        }
        if (playerNameFilter) {
            requestBody.playerName = playerNameFilter;
        }

        if (matchFilter && matchFilter.id !== 'all') {
            requestBody.match_id = matchFilter.match_id;
        }

        fetch(`${BASE_URL}/tracking/fetchTrackingPlayers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'

            },
            body: JSON.stringify(requestBody)
        })
            .then(response => response.json())
            .then(data => {
                setPlayerData(data.data.users);
                setFilteredData(data.data.users);
            })
            .catch(error => console.error('Error fetching table data:', error));
    }, [dateFromFilter, dateToFilter, matchFilter, playerNameFilter]);


    useEffect(() => {
        const requestBody = {};
        if (dateFromFilter) {
            requestBody.startDate = dateFromFilter;
        }
        if (dateToFilter) {
            requestBody.endDate = dateToFilter;
        }

        fetch(`${BASE_URL}/tracking/matches`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
            },
            body: JSON.stringify(requestBody)
        })
            .then(response => response.json())
            .then(data => {
                setMatchData(data.data.users);
            })
            .catch(error => console.error('Error fetching matches for dropdown:', error));
    }, [dateFromFilter, dateToFilter]);

    const columns = [
        { field: 'title', headerName: 'Name', flex: 1 },
        { field: 'feeds_count', headerName: 'Posts', flex: 1 },
        { field: 'players_visits', headerName: 'Unique Player Visits', flex: 1 },
        { field: 'player_total_views', headerName: 'Total Player Visits', flex: 1 },
        { field: 'feed_views', headerName: 'Unique Views', flex: 1 },
        { field: 'feed_total_views', headerName: 'Total Views', flex: 1 },
        { field: 'engagement', headerName: 'Engagement', flex: 1 },
        { field: 'rating_count', headerName: 'Number of Ratings', flex: 1 },
        { field: 'avg_rating', headerName: 'Average Rating', flex: 1 },
    ];

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);
        const filteredRows = playerData.filter((row) => {
            return Object.values(row).some((field) =>
                String(field).toLowerCase().includes(value)
            );
        });
        setFilteredData(filteredRows);
    };
    return (


        <Component>
            <div class="page-body">
                <div class="container-fluid">
                    <div class="page-header">
                        <div class="row">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center ">
                                    <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/content-dashboard">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <h3 style={{ marginLeft: '20px' }}>Player Dashboard</h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="row">


                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Autocomplete
                                                id="player-name-filter"
                                                options={playerNames}
                                                value={playerNameFilter}
                                                onChange={(event, newValue) => setPlayerNameFilter(newValue)}
                                                renderInput={(params) => <TextField {...params} label="Filter by Name" variant="outlined" />}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                label="From Date"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                value={dateFromFilter}
                                                onChange={(e) => setDateFromFilter(e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                label="To Date"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                value={dateToFilter}
                                                onChange={(e) => setDateToFilter(e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Autocomplete
                                                id="match-filter"
                                                options={[{ id: 'all', name: 'All' }, ...matchData]}
                                                getOptionLabel={(option) => option.title}
                                                value={matchFilter}
                                                onChange={(event, newValue) => {
                                                    setMatchFilter(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Select Match" variant="outlined" />}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <TextField
                                                fullWidth
                                                label="Search"
                                                variant="outlined"
                                                value={searchText}
                                                onChange={handleSearch}
                                            />
                                        </Grid>
                                    </Grid>

                                    <DataGrid
                                        rows={filteredData}
                                        columns={columns}
                                        pageSize={25}
                                        rowsPerPageOptions={[25, 50, 100]}
                                        getRowId={(row) => row.pid}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </Component>

    )
}

export default Players
