import React, { useState, useEffect, useRef } from 'react';
import Component from '../../components/Component'
import { BASE_URL, handleDynamicRequest } from '../../components/CommonExport';

import { TextField, Grid, Autocomplete, Button, TextareaAutosize, Menu, MenuItem, Radio, RadioGroup, FormControlLabel, FormControl, IconButton, } from '@mui/material';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DataGrid } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import AWS from 'aws-sdk';


const S3_BUCKET = 'qa-dugout';
const REGION = 'ap-south-1';
const ACCESS_KEY = 'AKIA5ASO4JECYTSYSU6R';
const SECRET_ACCESS_KEY = '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';

function Posts() {
    const [playerData, setPlayerData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [matchData, setMatchData] = useState([]);
    const [searchText, setSearchText] = React.useState('');
    const [filteredData, setFilteredData] = React.useState(playerData);
    const playerNames = playerData?.map(player => player.name);
    const [playerNameFilter, setPlayerNameFilter] = useState('');
    const [playerNameFilterNew, setPlayerNameFilterNew] = useState('');
    const [teamNameFilter, setTeamNameFilter] = useState('');
    const [teamNameFilterNew, setTeamNameFilterNew] = useState('');
    const [dateFromFilter, setDateFromFilter] = useState('');
    const [dateToFilter, setDateToFilter] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [matchFilter, setMatchFilter] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [innings, setInnings] = useState(null);
    const [selectedWall, setSelectedWall] = useState('match');
    const [teamFilterAdd, setTeamFilterAdd] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');
    const [randomString, setRandomString] = useState('');

    const initialFormData = {
        image_url: null,
        post: '',
    };

    const [formData, setFormData] = useState(initialFormData);

    const matches = useSelector((state) => state.matchReducer.matches);
    const toggle = () => setModal(!modal);
    const toggleEdit = () => setModalEdit(!modalEdit);

    useEffect(() => {
        fetchData();
    }, [matches, teamData, playerData]);
    useEffect(() => {
        fetchData();
    }, [teamNameFilter, playerNameFilter]);

    const fetchData = async () => {
        if (matches?.match_id) {
            try {
                const requestBody = {};

                if (playerNameFilter) {
                    const playerLatest = playerData.find((player) => player.name === playerNameFilter);
                    requestBody.player_id = playerLatest.player_id;
                }
                const response = await fetch(`${BASE_URL}/feeds/match/${matches.match_id}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
                        },
                        body: JSON.stringify(requestBody)
                    });
                const data = await response.json();

                if (data?.data?.feeds) {
                   

                    setMatchData(data.data.feeds);
                    setFilteredData(data.data.feeds);
                } else {
                    console.error('Invalid data structure:', data);
                }
            } catch (error) {
                console.error('Error fetching matches for dropdown:', error);
            }
        }
    };

    useEffect(() => {
        // Prepare request body
        const requestBody = {
            match_id: matches.match_id
        };


        fetch(`${BASE_URL}/tracking/match-players`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'

            },
            body: JSON.stringify(requestBody)
        })
            .then(response => response.json())
            .then(data => {
                setPlayerData(data.data.users);

            })
            .catch(error => console.error('Error fetching table data:', error));
    }, []);



    const columns = [
        { field: 'username', headerName: 'Username', flex: 1 },
        { field: 'over', headerName: 'Match Status', flex: 1 },
        { field: 'title', headerName: 'Player Name', flex: 1 },

        { field: 'content', headerName: 'Post', flex: 1 },
        {
            field: 'image_url',
            headerName: 'Photo',
            flex: 1,
            renderCell: (params) => (
                params.value ? (
                    <a href={params.value} target="_blank" rel="noopener noreferrer">
                        <img src={params.value} alt={params.row.title} style={{ width: '70px', height: '70px' }} />
                    </a>
                ) : (
                    <span></span>
                )
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => <ActionMenu {...params} fetchData={fetchData} />
        },

    ];

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);
        const filteredRows = playerData.filter((row) => {
            return Object.values(row).some((field) =>
                String(field).toLowerCase().includes(value)
            );
        });
        setFilteredData(filteredRows);
    };

    const handleChange = (event, field) => {
        const newValue = event.target ? event.target.value : event;
        setFormData(prevState => ({
            ...prevState,
            [field]: newValue
        }));
    };
    const handleChangeEdit = (event, fieldName) => {
        const { value } = event.target;

        // Update selectedUser based on the field name
        setSelectedUser(prevUser => ({
            ...prevUser,
            [fieldName]: value
        }));
    };

    const handleWallChange = (event) => {
        const value = event.target.value;
        console.log(value)
        setSelectedWall(value);
        if (value === 'match') {
            setFormData(prevFormData => ({ ...prevFormData, location: 'Match Wall' }));
            if (selectedUser && selectedUser.length > 0) {
                setSelectedUser(prevFormData => ({ ...prevFormData, location: 'Match Wall' }));
            }

        }
    };
    const handlePlayerChange = (event, newValue) => {

        if (selectedWall === 'player') {
            setFormData(prevFormData => ({ ...prevFormData, location: newValue }));
            setSelectedUser(prevFormData => ({ ...prevFormData, location: newValue }));
        }
    };


    const handleEdit = () => {
        toggleEdit();
        const formDataNew = new FormData();
        formDataNew.append('tid', teamNameFilterNew?.teamId);
        formDataNew.append('description', selectedUser.description);
        formDataNew.append('type', 'TEXT');
        formDataNew.append('over', selectedUser.over);
        formDataNew.append('player_id', playerNameFilterNew?.player_id);
        formDataNew.append('mid', matches?.match_id);
        formDataNew.append('image', null);
        formDataNew.append('innings', innings?.inningsName);
        fetch(`${BASE_URL}/insights/${selectedUser.id}`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
            },
            body: formDataNew
        })
            .then(response => response.json())
            .then(data => {
                fetchData();
                setFormData(initialFormData);
            })
            .catch(error => console.error('Error fetching table data:', error));
    }

    const handleSubmit = () => {
        const jsonData = {
            player_id: playerNameFilterNew?.player_id,
            match_id: matches?.match_id,
            content: formData.post,
            image: { uri: uploadedImageUrl }
              };
    
           toggle();
            fetch(`${BASE_URL}/feeds`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMGEyMjc3MWQtMzZjZi00ZDM1LTgwYzktNGVmYjUzOWJjMzc0IiwiaWF0IjoxNzE4MzU4MDI3LCJleHAiOjE3NDk0NjIwMjcsInBybSI6IjY1ZDA4OTFjYjEzNmFkMmQ4NmVkZjE2ZjM4Yjg1ZjUzMmZjZjViY2VlODQ1Y2E2OGFkYTUyYjRhY2RiNGZhZDMwNGY1MTk4YzlhYjBhZDBiMWMxNDA1YzEyMjMxNWVkNzI0MzdmMjgwNTYzYjY1MTA0ODhmYWI1OGM0YTNjNmFiIn0.rCxUv0JA6OHfu3U8ZdD1rKP1ROEpZlaV9qmQXjI8aZc'
            },
            body: JSON.stringify(jsonData)
        })
        .then(response => response.json())
        .then(data => {
            fetchData();
            setFormData(initialFormData);
        })
        .catch(error => console.error('Error fetching table data:', error));
    };
   
    const ActionMenu = (params) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleEdit = () => {
            handleClose();
            toggleEdit();

            setSelectedUser(params.row);

        };

        const handleDelete = () => {
            handleClose();
            fetch(`${BASE_URL}/feeds/${params.row.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMGEyMjc3MWQtMzZjZi00ZDM1LTgwYzktNGVmYjUzOWJjMzc0IiwiaWF0IjoxNzE4MzU4MDI3LCJleHAiOjE3NDk0NjIwMjcsInBybSI6IjY1ZDA4OTFjYjEzNmFkMmQ4NmVkZjE2ZjM4Yjg1ZjUzMmZjZjViY2VlODQ1Y2E2OGFkYTUyYjRhY2RiNGZhZDMwNGY1MTk4YzlhYjBhZDBiMWMxNDA1YzEyMjMxNWVkNzI0MzdmMjgwNTYzYjY1MTA0ODhmYWI1OGM0YTNjNmFiIn0.rCxUv0JA6OHfu3U8ZdD1rKP1ROEpZlaV9qmQXjI8aZc'
                }
            })
                .then(response => response.json())
                .then(data => {
                    fetchData();

                })
                .catch(error => console.error('Error fetching table data:', error));
        };

        return (
            <div>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </Menu>

            </div>
        );
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setIsUploading(true);

            const s3 = new AWS.S3({
                accessKeyId: ACCESS_KEY,
                secretAccessKey: SECRET_ACCESS_KEY,
                region: REGION,
            });

            const folderPath = `feedsImages/`; 
            const fileExtension = file.name.split('.').pop(); 
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().split('T')[0].replace(/-/g, '');
            const randomPart = Math.random().toString(36).substring(2, 15);
            const randomString = `${formattedDate}_${randomPart}`;
            setRandomString(randomString);
            const params = {
                Bucket: S3_BUCKET,
                Key: folderPath + randomString+'.' + fileExtension,
                Body: file,
            };

            s3.upload(params, (err, data) => {
                if (err) {
                    console.error(err);
                    setIsUploading(false);
                 
                    return;
                }
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    image_url: data.Location,
                  }));
                setUploadedImageUrl(data.Location);
                setIsUploading(false);
            });
        }
    };

    const handleDeleteImage = () => {
        const s3 = new AWS.S3({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
            region: REGION,
        });

        const folderPath = `feedsImages/`; // Specify the folder path
        const fileExtension = uploadedImageUrl.split('.').pop(); // Extract file extension


        const params = {
            Bucket: S3_BUCKET,
            Key: folderPath + randomString+'.' + fileExtension,
        };

        s3.deleteObject(params, (err, data) => {
            if (err) {
                console.error(err);
                return;
            }
            // Update UI or state as needed
            setFormData((prevFormData) => ({
                ...prevFormData,
                image_url: null,
              }));
            setUploadedImageUrl('');
        });
    };

    const handleButtonClick = () => {
        document.getElementById('file-input').click();

    };

    return (
        <>
            <div class="card-body">
                <Grid container spacing={2} sx={{ marginBottom: '20px' }}>

                    <Grid item xs={12} sm={12} md={5}>
                        <Autocomplete
                            id="player-name-filter"
                            options={playerNames}
                            value={playerNameFilter}
                            onChange={(event, newValue) => setPlayerNameFilter(newValue)}
                            renderInput={(params) => <TextField {...params} label="Filter by Player" variant="outlined" />}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <TextField
                            fullWidth
                            label="Search"
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                        <Button style={{ width: '100%', height: '100%' }} variant="contained" color="primary" onClick={toggle}>
                            Add Post
                        </Button>
                    </Grid>
                </Grid>
                {filteredData && filteredData.length > 0 && (
                    <DataGrid
                        rows={filteredData}
                        columns={columns}
                        pageSize={25}
                        rowsPerPageOptions={[25, 50, 100]}
                        getRowId={(row) => row.id}
                        rowHeight={100}
                    />
                )}
            </div>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Add Post</ModalHeader>
                <ModalBody>
                    <form className="form theme-form">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="player-name-filter"
                                    options={playerData}
                                    getOptionLabel={(option) => option?.name}
                                    value={playerNameFilterNew}
                                    onChange={(event, newValue) => setPlayerNameFilterNew(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Select Player" variant="outlined" />}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Post"
                                    variant="outlined"
                                    placeholder="Post"
                                    value={formData.post}
                                    onChange={(event) => handleChange(event, 'post')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Post Image"
                                    value={formData.image_name}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <Button variant="contained" onClick={handleButtonClick}>
                                                Upload
                                            </Button>
                                        )
                                    }}
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="file-input"
                                />
                                {formData.image_url && (
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                        <img src={formData.image_url} alt="Player" style={{ width: '100px', height: '100px', marginRight: '10px' }} />
                                        <IconButton onClick={handleDeleteImage} color="secondary">
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>Submit</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={modalEdit} toggle={toggleEdit}>
                <ModalHeader toggle={toggleEdit}>Edit Post</ModalHeader>
                <ModalBody>
                    <form className="form theme-form">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="player-name-filter"
                                    options={playerData}
                                    getOptionLabel={(option) => option?.name}
                                    value={playerNameFilterNew}
                                    onChange={(event, newValue) => setPlayerNameFilterNew(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Tag Team/Player" variant="outlined" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Post"
                                    variant="outlined"
                                    placeholder="Post"
                                    value={selectedUser ? selectedUser.post : formData.post}
                                    onChange={(event) => handleChangeEdit(event, 'post')}
                                />
                            </Grid>
                            <Grid item xs={12} container justifyContent="center" alignItems="center">
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="wall-type" name="wall-type" value={selectedWall} onChange={handleWallChange}>
                                        <FormControlLabel value="match" control={<Radio />} label="No Team" />
                                        <FormControlLabel value="player" control={<Radio />} label="Select Team" />
                                    </RadioGroup>
                                </FormControl>

                                {selectedWall === 'player' && (
                                    <Grid item xs={12}>

                                        <Autocomplete
                                            id="team-name-filter"
                                            options={teamData}
                                            getOptionLabel={(option) => option?.teamName}
                                            value={teamNameFilterNew}
                                            onChange={(event, newValue) => setTeamNameFilterNew(newValue)}
                                            renderInput={(params) => <TextField {...params} label="Filter by Team" variant="outlined" />}
                                            fullWidth
                                        />

                                    </Grid>

                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Write Highlight"
                                    variant="outlined"
                                    placeholder="Write Highlight"
                                    value={selectedUser.description}
                                    onChange={(event) => handleChangeEdit(event, 'description')}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleEdit}>Submit</Button>
                    <Button color="secondary" onClick={toggleEdit}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>


    )
}

export default Posts