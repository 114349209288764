import React, { useState, useEffect, useRef } from 'react';
import Component from '../../components/Component'
import { BASE_URL, handleDynamicRequest } from '../../components/CommonExport';

import {
    TextField, Grid, Autocomplete, Button, TextareaAutosize,
    Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Menu, MenuItem, IconButton
} from '@mui/material';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Switch } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';



function Predictions() {
    const [searchText, setSearchText] = React.useState('');
    const [filteredData, setFilteredData] = React.useState([]);
    const [predictionPollData, setpredictionPollData] = React.useState([]);
    const [modal, setModal] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [editChange, setEditChange] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const initialFormData = {
        description: "",
        type: "",
        start_time: "",
        end_time: "",
        match_id: ""
    };
    const typeMapping = (type) => {
        switch (type) {
          case 1:
            return 'Batsman';
          case 2:
            return 'Bowler';
          case 3:
            return 'All Rounder';
          case 4:
            return 'Wicket Keeper';
          case 5:
            return 'Fielder';
          default:
            return 'Unknown';
        }
      };



    const [formData, setFormData] = useState(initialFormData);

    const [editFormData, setEditFormData] = useState(initialFormData);

    const matches = useSelector((state) => state.matchReducer.matches);
    const toggle = () => {
        formData.match_id = matches.match_id;
        setModal(!modal)
    };




    useEffect(() => {
        fetchMatchData();
    }, [editChange]);


    const fetchMatchData = async () => {
        try {
            const response = await fetch(`${BASE_URL}/admin/prediction-polls/match/${matches.match_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMGEyMjc3MWQtMzZjZi00ZDM1LTgwYzktNGVmYjUzOWJjMzc0IiwiaWF0IjoxNzE4MzU4MDI3LCJleHAiOjE3NDk0NjIwMjcsInBybSI6IjY1ZDA4OTFjYjEzNmFkMmQ4NmVkZjE2ZjM4Yjg1ZjUzMmZjZjViY2VlODQ1Y2E2OGFkYTUyYjRhY2RiNGZhZDMwNGY1MTk4YzlhYjBhZDBiMWMxNDA1YzEyMjMxNWVkNzI0MzdmMjgwNTYzYjY1MTA0ODhmYWI1OGM0YTNjNmFiIn0.rCxUv0JA6OHfu3U8ZdD1rKP1ROEpZlaV9qmQXjI8aZc'
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch match data');
            }
            const data = await response.json();
            const predictionPolls = data.data.map(poll => ({
                ...poll,
                start_time: adjustTimezone(poll.start_time),
                end_time: adjustTimezone(poll.end_time)
            }));
            console.log('Prediction Polls:', predictionPolls);
            setpredictionPollData(predictionPolls);
            setFilteredData(predictionPolls);
        } catch (error) {
            console.error('Error fetching matches for dropdown:', error);
        }
    };

    // Function to adjust time zone from GMT to IST
    const adjustTimezone = (time) => {
        const date = new Date(time);
        date.setUTCHours(date.getUTCHours());
        return date
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
        const filteredData = predictionPollData.filter((poll) =>
            poll.description.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setFilteredData(filteredData);
    }

    const ToggleEditModal = () => {
        setModalEdit(!modalEdit)
    }

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    const handleStatusToggle = (params) => {
        try {
            console.log('these are the params', params)
            const response = axios.put(`${BASE_URL}/admin/prediction-polls/${params.row.id}`, {
                is_active: !params.value
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMGEyMjc3MWQtMzZjZi00ZDM1LTgwYzktNGVmYjUzOWJjMzc0IiwiaWF0IjoxNzE4MzU4MDI3LCJleHAiOjE3NDk0NjIwMjcsInBybSI6IjY1ZDA4OTFjYjEzNmFkMmQ4NmVkZjE2ZjM4Yjg1ZjUzMmZjZjViY2VlODQ1Y2E2OGFkYTUyYjRhY2RiNGZhZDMwNGY1MTk4YzlhYjBhZDBiMWMxNDA1YzEyMjMxNWVkNzI0MzdmMjgwNTYzYjY1MTA0ODhmYWI1OGM0YTNjNmFiIn0.rCxUv0JA6OHfu3U8ZdD1rKP1ROEpZlaV9qmQXjI8aZc'
                    }
                })

            setEditChange(!editChange);


        } catch (err) {
            console.error('Error:', err);
            window.alert('Error in submitting data');
        }
    }




    const columns = [
        { field: 'description', headerName: 'Description', flex: 1 },
        { field: 'type', headerName: 'Type', flex: 1, renderCell: (params) => typeMapping(Number(params.value)) },
        { field: 'start_time', headerName: 'Start Time', flex: 1 },

        { field: 'end_time', headerName: 'End Time', flex: 1 },
        {
            field: 'is_active',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Switch
                        checked={params.row.is_active}
                        onChange={() => handleStatusToggle(params)}
                        color="primary"
                    />
                )
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => <ActionMenu {...params} />
        },

    ];

    const ActionMenu = (params) => {
        const [anchorEl, setAnchorEl] = useState(null);
        console.log('action params', params)
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };


        return (
            <div>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => {
                        setEditFormData({
                            description: params.row.description,
                            type: params.row.type,
                            start_time: params.row.start_time,
                            end_time: params.row.end_time,
                            match_id: params.row.match_id
                        });
                        setEditingId(params.row.id);
                        setModalEdit(!modalEdit)
                    }
                    }>Edit</MenuItem>
                    <MenuItem onClick={()=>{
                        setEditingId(params.row.id);
                        setDeleteModal(!deleteModal)
                        }}>Delete</MenuItem>
                </Menu>
            </div>
        );
    };

    const handleEdit = async(formdata) => {
        try{
            const response = await axios.put(`${BASE_URL}/admin/prediction-polls/${editingId}`, editFormData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMGEyMjc3MWQtMzZjZi00ZDM1LTgwYzktNGVmYjUzOWJjMzc0IiwiaWF0IjoxNzE4MzU4MDI3LCJleHAiOjE3NDk0NjIwMjcsInBybSI6IjY1ZDA4OTFjYjEzNmFkMmQ4NmVkZjE2ZjM4Yjg1ZjUzMmZjZjViY2VlODQ1Y2E2OGFkYTUyYjRhY2RiNGZhZDMwNGY1MTk4YzlhYjBhZDBiMWMxNDA1YzEyMjMxNWVkNzI0MzdmMjgwNTYzYjY1MTA0ODhmYWI1OGM0YTNjNmFiIn0.rCxUv0JA6OHfu3U8ZdD1rKP1ROEpZlaV9qmQXjI8aZc'
                }
            });
            console.log(response)
            setEditChange(!editChange);
            setModalEdit(!modalEdit);
            setEditingId(null);
        }catch(err){
            console.error('Error:', err);
            window.alert('Error in submitting data');
        }


    }
    const handleDelete = async() => {
        try{
            const response = await axios.delete(`${BASE_URL}/admin/prediction-polls/${editingId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMGEyMjc3MWQtMzZjZi00ZDM1LTgwYzktNGVmYjUzOWJjMzc0IiwiaWF0IjoxNzE4MzU4MDI3LCJleHAiOjE3NDk0NjIwMjcsInBybSI6IjY1ZDA4OTFjYjEzNmFkMmQ4NmVkZjE2ZjM4Yjg1ZjUzMmZjZjViY2VlODQ1Y2E2OGFkYTUyYjRhY2RiNGZhZDMwNGY1MTk4YzlhYjBhZDBiMWMxNDA1YzEyMjMxNWVkNzI0MzdmMjgwNTYzYjY1MTA0ODhmYWI1OGM0YTNjNmFiIn0.rCxUv0JA6OHfu3U8ZdD1rKP1ROEpZlaV9qmQXjI8aZc'
                }
            });
            setEditChange(!editChange);
            setEditingId(null);
            setDeleteModal(!deleteModal);

        }catch(err){
            console.error('Error:', err);
            window.alert('Error in submitting data');
        }
        

    }
    
    const formatDateTime = (date) => {
        if (!date) return '';
    
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}`;
      };
    const handleSubmit = async () => {
        console.log('this is formData', formData);
        try {

            const response = await axios.post(`${BASE_URL}/admin/prediction-polls`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMGEyMjc3MWQtMzZjZi00ZDM1LTgwYzktNGVmYjUzOWJjMzc0IiwiaWF0IjoxNzE4MzU4MDI3LCJleHAiOjE3NDk0NjIwMjcsInBybSI6IjY1ZDA4OTFjYjEzNmFkMmQ4NmVkZjE2ZjM4Yjg1ZjUzMmZjZjViY2VlODQ1Y2E2OGFkYTUyYjRhY2RiNGZhZDMwNGY1MTk4YzlhYjBhZDBiMWMxNDA1YzEyMjMxNWVkNzI0MzdmMjgwNTYzYjY1MTA0ODhmYWI1OGM0YTNjNmFiIn0.rCxUv0JA6OHfu3U8ZdD1rKP1ROEpZlaV9qmQXjI8aZc'
                }
            });
            setEditChange(!editChange);
            setModal(!modal);
            setFormData(initialFormData);
            console.log('Response:', response.data);
            window.alert('Data submitted successfully');

        } catch (err) {
            console.error('Error:', err);
            window.alert('Error in submitting data');
        }
    };
    return (


        <>

            <div class="card-body">
                <Grid container spacing={2} sx={{ marginBottom: '20px' }}>


                    <Grid item xs={12} sm={12} md={5}>
                        <TextField
                            fullWidth
                            label="Search"
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                        <Button style={{ width: '100%', height: '100%' }} variant="contained" color="primary" onClick={toggle}>
                            Add Prediction
                        </Button>
                    </Grid>
                </Grid>
                <DataGrid
                    rows={filteredData}
                    columns={columns}
                    pageSize={25}
                    rowsPerPageOptions={[25, 50, 100]}
                    getRowId={(row) => row.id}
                />
            </div>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Add Polls</ModalHeader>
                <ModalBody>
                    <form className="form theme-form">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    variant="outlined"
                                    placeholder="Prediction description"
                                    value={formData.description}
                                    onChange={(event) => setFormData({ ...formData, description: event.target.value })}
                                />
                            </Grid>

                            <Grid item xs={12} container justifyContent="center" alignItems="center">
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="type" name="type" value={formData.type} onChange={(event) => {
                                        setFormData({ ...formData, type: Number(event.target.value) })
                                    }}>
                                        <FormControlLabel value='1' control={<Radio />} label="Batsmen" />
                                        <FormControlLabel value='2' control={<Radio />} label="Bowler" />
                                        <FormControlLabel value='3' control={<Radio />} label="All Rounder" />
                                        <FormControlLabel value='4' control={<Radio />} label="Wicket Keeper" />
                                        <FormControlLabel value='5' control={<Radio />} label="Fielder" />
                                    </RadioGroup>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    label="Start Time (UTC)"
                                    type="datetime-local"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.start_time}
                                    onChange={(e) => setFormData({ ...formData, start_time: e.target.value })}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    label="End Time (UTC)"
                                    type="datetime-local"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.end_time}
                                    onChange={(e) => setFormData({ ...formData, end_time: e.target.value })}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalBody>
                <ModalFooter >
                    <Button color="primary" onClick={handleSubmit}>Submit</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={modalEdit} toggle={ToggleEditModal}>
                <ModalHeader>Edit Polls</ModalHeader>
                <ModalBody>
                    <form className="form theme-form">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    variant="outlined"
                                    placeholder="Prediction description"
                                    value={editFormData.description}
                                    onChange={(event) => setEditFormData({ ...editFormData, description: event.target.value })}
                                />
                            </Grid>

                            <Grid item xs={12} container justifyContent="center" alignItems="center">
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="type" name="type" value={editFormData.type} onChange={(event) => {
                                        setEditFormData({ ...editFormData, type: Number(event.target.value) })
                                    }}>
                                        <FormControlLabel value='1' control={<Radio />} label="Batsmen" />
                                        <FormControlLabel value='2' control={<Radio />} label="Bowler" />
                                        <FormControlLabel value='3' control={<Radio />} label="All Rounder" />
                                        <FormControlLabel value='4' control={<Radio />} label="Wicket Keeper" />
                                        <FormControlLabel value='5' control={<Radio />} label="Fielder" />
                                    </RadioGroup>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    label="Start Time (IST)"
                                    type="datetime-local"
                                    variant="outlined"
                                    fullWidth
                                    value={formatDateTime(editFormData.start_time)}
                                    onChange={(e) => setEditFormData({ ...editFormData, start_time: new Date(e.target.value) })}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    label="End Time (IST)"
                                    type="datetime-local"
                                    variant="outlined"
                                    fullWidth
                                    value={formatDateTime(editFormData.end_time)}
                                    onChange={(e) => setEditFormData({ ...editFormData, end_time: new Date(e.target.value) })}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalBody>
                <ModalFooter >
                    <Button color="primary" onClick={handleEdit}>Submit</Button>
                    <Button color="secondary" onClick={ToggleEditModal}>Cancel</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader>Delete Poll</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this poll?
                </ModalBody>
                <ModalFooter >
                    <Button color="primary" onClick={handleDelete}>Yes</Button>
                    <Button color="secondary" onClick={toggleDeleteModal}>No</Button>
                </ModalFooter>
            </Modal>


            


        </>


    )
}

export default Predictions
